export function Disclaimer() {
    return (
        <div className="w-full mx-auto">
            <h1>Risk Disclaimer</h1>
            <h2 className='mt-4'>No Investment Advice</h2>
            <p className='mt-2 text-neutral-400'>This website and its contents are for informational purposes only, and none of it is intended to constitute, or constitutes, financial advice or a recommendation or inducement to enter into any transaction. The data displayed on this website includes data taken from external sources and no guarantee or warranty is provided as to its accuracy, reliability, availability or safety. If you choose to make use of any of the information displayed on this website, you do so entirely at your own risk - cryptoncarry.com is not responsible for any losses suffered by any party in connection with that use, including trading losses.</p>
            
            <h2 className='mt-4'>Affiliate Links</h2>
            <p className='mt-2 text-neutral-400'>cryptoncarry.com may receive financial compensation for affiliate links. Should you perform any action using such an affiliate link it is understood that some form of compensation may be made to cryptoncarry.com.</p>
            
            <h2 className='mt-4'>Non Endorsement</h2>
            <p className='mt-2 text-neutral-400'>No material present on this website constitutes an endoresement, guarantee or reccomendations of any platform, project, currency or other third party. You should carry out your own due diligence in regards to any third party services.</p>
        </div>
    )
}