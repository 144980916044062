const BASE_URL = 'https://cryptoncarry.sgp1.cdn.digitaloceanspaces.com/crypto_icons'

export interface CryptoIconProps {
    name: string
    className?: string
}

export function CryptoIcon(props: CryptoIconProps) {
    const name = props.name.toLowerCase()
    const className = props.className
    const url = `${BASE_URL}/${name}.png`
    return <img 
        alt={props.name}
        src={url}
        className={className}
        onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src=`${BASE_URL}/generic.png`;
        }}
        />
}