import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import DarkUnica from 'highcharts/themes/dark-unica'
import { useEffect, useState } from 'react'
import { fetchFunding } from '../api'

export const SECONDS_IN_DAY = 24 * 60 * 60

const DEFAULT_CHART: any = {
    chart: {
        backgroundColor: '#334155',
        borderRadius: '0.5rem',
        height: '337rem'
    }
}

interface FundingMiniChartSeries {
    exchange: string
    symbol: string
}

interface FundingMiniChartProps {
    series: FundingMiniChartSeries[]
}

export function FundingMiniChart(props: FundingMiniChartProps) {
    const [options,setOptions] = useState(DEFAULT_CHART)

    DarkUnica(Highcharts)

    useEffect(() => {
        const series = props.series
        if(series.length === 0) {
            return
        }
        const getData = async () => {
            const end = Math.ceil((new Date()).getTime() / 1000)
            const start = end - 30 * SECONDS_IN_DAY
            const data = await Promise.all(series.map((x: FundingMiniChartSeries) => {
                return fetchFunding(x.exchange,x.symbol,start,end)
            }))
            console.log(data)
            const formatted = data.map((series: any) => {
                return series.map((x: any) => [new Date(x.timestamp).getTime() ,100 * x.fundingRate])
            })
            const yMin = Math.min(0,...formatted.flat().map((x: any) => x[1]))
            const yMax = Math.max(0,...formatted.flat().map((x: any) => x[1]))
            setOptions({
                ...DEFAULT_CHART,
                series: series.map((x: FundingMiniChartSeries, i: number) => {
                    return {
                        name: `${x.exchange} | ${x.symbol}`,
                        data: formatted[i],
                        // color: '#fcd34d'
                    }
                }),
                yAxis: {
                    title: {
                        text: 'Funding Rate (%)'
                    },
                    plotLines: [
                        {
                            value: 0,
                            width: 3,
                            color: '#0284c7'
                        }
                    ],
                    max: yMax,
                    min: yMin
                },
                xAxis: {
                    type: "datetime",
                    ordinal: false
                },
                navigator: {
                    enabled: false
                }
            })
          }
          getData()
    },[props.series])

    return (
        <div className='mt-4'>
            <HighchartsReact 
                highcharts={Highcharts} 
                constructorType={'stockChart'}
                options={options}
            />
        </div>
    )
}