import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchTrade } from '../api'
import FundingArbTrade from './FundingArbTrade'
import VariableAndFixedBasisTrade from './VariableAndFixedBasisTrade'


function Trade() {
  const { id } = useParams()
  const [loading,setLoading] = useState(true)
  const [showError,setShowError] = useState(false)
  const [trade,setTrade] = useState({} as any)

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await fetchTrade(id!)
        setTrade(data)
        setLoading(false)
      } catch(e) {
        console.error(e)
        setShowError(true)
      }
    }
    getData()
  },[id])

  if(showError) {
    return <div className="w-full mx-auto text-center">
      <h1 className='text-amber-400'>404</h1>
      <h2>Trade Not Found</h2>
    </div>
  }

  if(loading) {
    return <div className="w-full mx-auto text-center">Loading...</div>
  }

  if(['FixedBasis','VariableBasis'].includes(trade.type)) {
    return <VariableAndFixedBasisTrade id={id!} trade={trade} />
  }
  if(['FundingArb'].includes(trade.type)) {
    return <FundingArbTrade id={id!} trade={trade} />
  }
  
  return <div className="w-full mx-auto text-center">
      <h1 className='text-amber-400'>404</h1>
      <h2>Invalid Trade Type</h2>
    </div> 
}

export default Trade