import { useMemo } from "react";
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { Analytics } from "./components/Analytics";
import Navbar from "./components/navbar/Navbar";
import About from "./views/About";
import Basis from "./views/Basis";
import Collateral from "./views/Collateral";
import { Disclaimer } from "./views/Disclaimer";
import Funding from "./views/Funding";
import Futures from "./views/Futures";
import Home from "./views/Home";
import Perpetuals from "./views/Perpetuals";
import Trade from "./views/Trade";
import Trades from "./views/Trades";

interface AdapterParams {
  children: any;
}
const RouteAdapter = (params: AdapterParams) => {
  const children: any = params.children;
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = useMemo(
    () => ({
      replace(location: any) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location: any) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate]
  );
  return children({ history: adaptedHistory, location });
};

function App() {

  return (
    <BrowserRouter>
      <Analytics />
      <QueryParamProvider ReactRouterRoute={RouteAdapter as any}>
        <div className="relative min-h-screen pb-14 text-neutral-300">
          <Navbar />
          <section className="lg:pb-32 pb-72">
            <div className="container flex flex-col items-center py-4 lg:py-8 mx-auto w-screen-xl px-6 lg:px-8">
              <div className="flex flex-col w-full max-w-full mx-auto">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/futures" element={<Futures />} />
                  <Route path="/perpetuals" element={<Perpetuals />} />
                  <Route path="/trades" element={<Trades />} />
                  <Route path="/trade/:id" element={<Trade />} />
                  <Route path="/funding" element={<Funding />} />
                  <Route path="/basis" element={<Basis />} />
                  <Route path="/collateral" element={<Collateral />} />
                  <Route path="/disclaimer" element={<Disclaimer />} />
                  <Route path="/about" element={<About />} />
                </Routes>
              </div>
            </div>
          </section>
          <footer
            className="absolute bottom-0 w-full"
            aria-labelledby="footer-heading"
          >
            <div className="flex px-4 py-4 mx-auto sm:px-6 lg:px-16 justify-center">
              <div className="flex flex-wrap items-baseline text-center justify-center w-full lg:w-1/2">
                <div className="mt-2 text-sm font-light text-gray-500">
                  This website and its contents are for informational purposes
                  only, and none of it is intended to constitute, or
                  constitutes, financial advice or a recommendation or
                  inducement to enter into any transaction. The data displayed
                  on this website includes data taken from external sources and
                  no guarantee or warranty is provided as to its accuracy,
                  reliability, availability or safety. If you choose to make use
                  of any of the information displayed on this website, you do so
                  entirely at your own risk - cryptoncarry.com is not
                  responsible for any losses suffered by any party in connection
                  with that use, including trading losses. Read the{" "}
                  <Link to="/disclaimer">full risk disclaimer</Link>.
                </div>
              </div>
            </div>
          </footer>
        </div>
      </QueryParamProvider>
    </BrowserRouter>
  );
}

export default App;
