import moment from "moment";
import { Link } from "react-router-dom";
import Advert from "../components/Advert";
import { BasisTypeIcon } from "../components/BasisTypeIcon";
import { CryptoIcon } from "../components/CryptoIcon";
import { ExchangeIcon } from "../components/ExchangeIcon";
import { ExpandableDescription } from "../components/ExpandableDescription";
import { FundingMiniChart } from "../components/FundingMiniChart";
import { ImpactPercentage } from "../components/ImpactPercentage";
import ShareButtons from "../components/ShareButtons";
import { SideIcon } from "../components/SideIcon";
import { getReferralBaseLink } from "../referral";

interface VariableAndFixedBasisTradeProps {
  id: string;
  trade: any;
}

function FundingArbTrade(props: VariableAndFixedBasisTradeProps) {
  const { trade } = props;

  const tradeSpiel =
    "A funding arb trade is going both long and short a perpetual with the same underlying, but on two different exchanges. The trade attempts to earn the difference between the funding rates of the two pereptuals, while remaining delta neutral.";

  const columns = [
    "Type",
    "Notional",
    "Currency",
    "Duration",
    "% Return",
    "% Ann. Return",
    "Last Updated",
    "Share",
  ];
  const values = [
    <BasisTypeIcon type={trade.type} />,
    trade.notional.toLocaleString(),
    trade.quoteCurrency,
    `${trade.payload.holdTimeDays.toFixed(1)} days`,
    <span
      className={trade.payload.return > 0 ? "text-green-500" : "text-red-500"}
    >{`~${(100 * trade.payload.return).toFixed(2)}%`}</span>,
    <span
      className={`text-2xl ${
        trade.payload.annualizedReturn > 0 ? "text-green-500" : "text-red-500"
      }`}
    >{`~${(100 * trade.payload.annualizedReturn).toFixed(2)}%`}</span>,
    moment(trade.timestamp).fromNow(),
    <ShareButtons />,
  ];
  const summary = [];
  for (let i = 0; i < columns.length; i++) {
    summary.push(
      <div key={i} className="flex lg:flex-row flex-row text-lg">
        <div className="text-center font-bold">{columns[i]}:</div>
        <div className="text-center ml-1">{values[i]}</div>
      </div>
    );
  }

  // TODO: Get this from API?
  const underlying = trade.payload.underlying;
  const shortLeg = [];
  shortLeg.push([
    "Symbol",
    <span>
      <CryptoIcon
        className="w-4 h-4 inline-block mr-1 mb-1"
        name={underlying}
      />
      <a href={trade.payload.shortPerpetualLeg.tradeLink}>
        {trade.payload.shortPerpetualLeg.symbol}
      </a>
      <Link
        className="no-underline"
        to={`funding?exchange=${trade.payload.shortPerpetualLeg.exchange}&symbol=${trade.payload.shortPerpetualLeg.symbol}`}
      >
        {" "}
        <img
          src="/img/icons/chart-area-solid.svg"
          className="w-5 inline-block ml-1 mb-1"
          alt={`Funding History`}
          title={`Funding History`}
        />
      </Link>
    </span>,
  ]);
  shortLeg.push([
    "Exchange",
    <span>
      <ExchangeIcon
        className="w-4 inline-block mb-1 mr-1"
        name={trade.payload.shortPerpetualLeg.exchange}
      />
      <a href={getReferralBaseLink(trade.payload.shortPerpetualLeg.exchange)}>
        {trade.payload.shortPerpetualLeg.exchange}
      </a>
    </span>,
  ]);
  shortLeg.push([
    "Side",
    <SideIcon side={trade.payload.shortPerpetualLeg.side} />,
  ]);
  shortLeg.push([
    "Quantity",
    trade.payload.shortPerpetualLeg.quantity.toLocaleString(),
  ]);
  shortLeg.push([
    "Fee",
    <span>{`${(100 * trade.payload.shortPerpetualLeg.fees).toFixed(
      3
    )}%`}</span>,
  ]);
  shortLeg.push([
    "Spread",
    <ImpactPercentage pct={100 * trade.payload.shortPerpetualLeg.spread} />,
  ]);
  shortLeg.push([
    "Slippage",
    <ImpactPercentage pct={100 * trade.payload.shortPerpetualLeg.slippage} />,
  ]);
  shortLeg.push([
    "Price Impact",
    <ImpactPercentage pct={100 * trade.payload.shortPerpetualLeg.impact} />,
  ]);

  const shortPerpetualLegElements = [];
  for (const x of shortLeg) {
    shortPerpetualLegElements.push(
      <div key={x[0]} className="flex lg:flex-row flex-row text-lg">
        <div className="text-center font-bold">{x[0]}:</div>
        <div className="text-center ml-1">{x[1]}</div>
      </div>
    );
  }

  const longLeg = [];
  const longLegPayload = trade.payload.longPerpetualLeg;
  const holdTime = trade.payload.holdTimeDays;

  longLeg.push([
    "Symbol",
    <span>
      <CryptoIcon
        className="w-4 h-4 inline-block mr-1 mb-1"
        name={underlying}
      />
      <a href={longLegPayload.tradeLink}>{longLegPayload.symbol}</a>
      <Link
        className="no-underline"
        to={`funding?exchange=${longLegPayload.exchange}&symbol=${longLegPayload.symbol}`}
      >
        {" "}
        <img
          src="/img/icons/chart-area-solid.svg"
          className="w-5 inline-block ml-1 mb-1"
          alt={`Funding History`}
          title={`Funding History`}
        />
      </Link>
    </span>,
  ]);
  longLeg.push([
    "Exchange",
    <span>
      <ExchangeIcon
        className="w-4 inline-block mb-1 mr-1"
        name={longLegPayload.exchange}
      />
      <a href={getReferralBaseLink(longLegPayload.exchange)}>
        {longLegPayload.exchange}
      </a>
    </span>,
  ]);
  longLeg.push(["Side", <SideIcon side={longLegPayload.side} />]);
  longLeg.push(["Quantity", longLegPayload.quantity.toLocaleString()]);
  longLeg.push([
    "Fee",
    <span>{`${(100 * longLegPayload.fees).toFixed(3)}%`}</span>,
  ]);
  longLeg.push([
    "Spread",
    <ImpactPercentage pct={100 * longLegPayload.spread} />,
  ]);
  longLeg.push([
    "Slippage",
    <ImpactPercentage pct={100 * longLegPayload.slippage} />,
  ]);
  longLeg.push([
    "Price Impact",
    <ImpactPercentage pct={100 * longLegPayload.impact} />,
  ]);

  const longLegElements = [];
  for (const x of longLeg) {
    longLegElements.push(
      <div key={x[0]} className="flex lg:flex-row flex-row text-lg">
        <div className="text-center font-bold">{x[0]}:</div>
        <div className="text-center ml-1">{x[1]}</div>
      </div>
    );
  }

  const sameExchange =
    trade.payload.shortPerpetualLeg.exchange === longLegPayload.exchange;

  const holdTimeText = `Funding arb trades are assumed to be held for ${holdTime} days, using the ${holdTime} day average funding rate.`;

  return (
    <div className="w-full mx-auto">
      <Advert />
      <div className="flex flex-col lg:flex-row">
        <div className="bg-slate-600 rounded-md p-2 flex-grow lg:mr-2 basis-0">
          <h1 className="mb-4">Trade Summary</h1>
          {summary}
          <strong>Description: </strong>
          <ExpandableDescription text={tradeSpiel} />
          <small className="text-neutral-300">
            *All returns are approximate.
          </small>
          <br />
          <small className="text-neutral-300">{`*${holdTimeText}`}</small>
        </div>
        <div className="bg-slate-600 rounded-md p-2 flex-grow lg:ml-2 mt-4 lg:mt-0 basis-0">
          <h1 className="mb-4">{`Funding History`}</h1>
          <FundingMiniChart
            series={[
              {
                exchange: trade.payload.shortPerpetualLeg.exchange,
                symbol: trade.payload.shortPerpetualLeg.symbol,
              },
              {
                exchange: trade.payload.longPerpetualLeg.exchange,
                symbol: trade.payload.longPerpetualLeg.symbol,
              },
            ]}
          />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="bg-slate-600 rounded-md p-2 lg:mr-2 flex-grow mt-4 basis-0">
          <h1 className="mb-4">Short Perpetual Leg</h1>
          {shortPerpetualLegElements}
        </div>
        <div className="bg-slate-600 rounded-md p-2 lg:ml-2 flex-grow mt-4 basis-0">
          <h1 className="mb-4">Long Perpetual Leg</h1>
          {longLegElements}
        </div>
      </div>
      <div className="bg-slate-600 rounded-md p-2 mt-4">
        <h1 className="mb-2">Instructions</h1>
        <div className="text-sm mb-4">
          <span className="text-red-500 font-bold">RISK DISCLAIMER:</span>{" "}
          Trading cryptocurrencies carries a high level of risk, and may not be
          suitable for all investors. This website and its contents are for
          informational purposes only, and none of it is intended to constitute,
          or constitutes, financial advice or a recommendation or inducement to
          enter into any transaction. The data displayed on this website
          includes data taken from external sources and no guarantee or warranty
          is provided as to its accuracy, reliability, availability or safety.
          If you choose to make use of any of the information displayed on this
          website, you do so entirely at your own risk - cryptoncarry.com is not
          responsible for any losses suffered by any party in connection with
          that use, including trading losses.
        </div>
        <div className="pl-6">
          <ol className="text-lg list-decimal space-y-1">
            <li>
              Go to{" "}
              <a
                href={getReferralBaseLink(
                  trade.payload.shortPerpetualLeg.exchange
                )}
              >{`${trade.payload.shortPerpetualLeg.exchange}`}</a>{" "}
              and sign up for an account / deposit collateral.
            </li>

            <li>
              <span className="font-bold text-red-500">IMPORTANT: </span>Verify
              that the current market conditions for this trade are still
              viable.
            </li>

            <li>
              {trade.payload.shortPerpetualLeg.side === "buy" ? "Buy" : "Sell"}{" "}
              {trade.payload.shortPerpetualLeg.quantity.toLocaleString()}{" "}
              {underlying} of{" "}
              <a href={trade.payload.shortPerpetualLeg.tradeLink}>
                {trade.payload.shortPerpetualLeg.symbol}
              </a>
              .
            </li>

            {!sameExchange && (
              <li>
                Go to{" "}
                <a href={getReferralBaseLink(longLegPayload.exchange)}>
                  {longLegPayload.exchange}
                </a>{" "}
                and sign up for an account / deposit collateral.
              </li>
            )}

            <li>
              {longLegPayload.side === "buy" ? "Buy" : "Sell"}{" "}
              {trade.payload.shortPerpetualLeg.quantity.toLocaleString()}{" "}
              {underlying} of{" "}
              <a href={longLegPayload.tradeLink}>{longLegPayload.symbol}</a>{" "}
              {longLegPayload.isInverse &&
                `(~${longLegPayload.quantity.toLocaleString()} contracts)`}
              .
            </li>

            <li>
              Keep track of the funding rate to make sure that the trade does
              not start to become unprofitable. It is possible to keep the trade
              going indefinitely while funding is favourable.
            </li>

            {longLegPayload.exchange === "FTX" && (
              <li>
                <span className="font-bold text-red-500">WARNING: </span>On FTX,
                under certain conditions (such as your negative USD balance
                being in excess of $30,000) your non-USD collateral{" "}
                <a href="https://help.ftx.com/hc/en-us/articles/360031149632-Non-USD-Collateral">
                  may be liquidated
                </a>
                .
              </li>
            )}
          </ol>
        </div>
      </div>
      <div className="bg-slate-600 rounded-md p-2 mt-4">
        <h1 className="mb-4">Risks</h1>
        <ol className="text-lg list-item space-y-4">
          <li>
            <strong className="text-red-500">💦 Liquidation Risk</strong>
            <br /> When selling the {longLegPayload.product}, if less collateral
            is used then there is risk of liquidation. Pay close attention to
            how your collateral is valued on the exchange.
          </li>
          <li>
            <strong className="text-red-500">💰 Funding Risk</strong>
            <br /> With perpetual futures, the funding rate can change over
            time, or even flip sides. Pay attention to the funding rate and
            consider closing the trade if the funding is consistently
            unfavourable.
          </li>
          <li>
            <strong className="text-red-500">📉 Basis Risk</strong>
            <br /> With perpetual futures, the funding rate should keep the
            price of the perpetual tethered to the underlying. However, unlike
            with futures this is not guaranteed and the difference can increase
            or decrease leading to losses.
          </li>
          <li>
            <strong className="text-red-500">💣 Socialised Loss Risk</strong>
            <br /> Most exchanges will have some sort of{" "}
            <a href="https://www.machow.ski/posts/how-crypto-derivatives-exchanges-prevent-bankruptcy/">
              socialised loss mechanism
            </a>
            . During extreme market moves it is possible for your{" "}
            {longLegPayload.product} to be closed early.
          </li>
          <li>
            <strong className="text-red-500">
              🏦 Exchange Counterparty Risk
            </strong>
            <br /> By putting funds in an exchange, you are putting your funds
            in their hands, and therefore at risk. Carrying out your own due
            dilligence is reccomended.
          </li>
        </ol>
      </div>
    </div>
  );
}

export default FundingArbTrade;
