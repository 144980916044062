interface ColoredPercentageProps {
    value: number
    precision?: number
}
export function ColoredPercentage(props: ColoredPercentageProps) {
    const precision = props.precision || 2
    const value = props.value
    return (
        <span className={value > 0 ? 'text-green-500' : 'text-red-500'}>{`${value.toFixed(precision)}%`}</span>
    )
}