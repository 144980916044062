const BASE_URL = 'https://cryptoncarry.sgp1.cdn.digitaloceanspaces.com/exchange_icons'

export interface ExchangeIconProps {
    name: string
    className?: string
}

export function ExchangeIcon(props: ExchangeIconProps) {
    const name = props.name.toLowerCase()
    const className = props.className
    const url = `${BASE_URL}/${name}.png`
    return <img 
        alt={props.name}
        src={url}
        className={className}
        />
}