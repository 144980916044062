import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { fetchFutures } from '../api'
import Advert from '../components/Advert'
import FuturesTable from '../components/FuturesTable'

function Futures() {
    const [futuresData,setFuturesData] = useState([] as any[])
    useEffect(() => {
      const getData = async () => {
        const data = await fetchFutures()
        setFuturesData(data)
      }
      getData()
    },[])
    return (
        <div className="w-full mx-auto">
            <Advert />
            <h1>Futures</h1>
            <p className="text-sm text-neutral-500">Details of all futures being tracked. Find out more about futures <Link to="/about">here</Link></p>
            <FuturesTable data={futuresData}/>
        </div>
    )
}

export default Futures