import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { Column, usePagination, useSortBy, useTable } from 'react-table'
import ReactTooltip from 'react-tooltip'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import { compactNumber, translateExchangeName } from '../common'
import { getReferralBaseLink } from '../referral'
import { ColoredPercentage } from './ColoredPercentage'
import { CryptoIcon } from './CryptoIcon'
import { ExchangeIcon } from './ExchangeIcon'

interface PerpetualsRow {
    symbol: string
    underlying: string
    exchange: string
    fundingPeriodSeconds: number
    annualizedAvgFundingRate1h: number
    annualizedAvgFundingRate8h: number
    annualizedAvgFundingRate24h: number
    annualizedAvgFundingRate3d: number
    annualizedAvgFundingRate7d: number
    annualizedAvgFundingRate30d: number
    annualizedAvgFundingRate90d: number
    spreadPct: number
    volumeUsd24h: number
    tradeLink: string
    typ: string
    updatedAt: number
}

function PerpetualsTable(props: any) {
    const columns: Column<PerpetualsRow>[] = React.useMemo(
        () => [
          {
            Header: 'Symbol',
            accessor: 'symbol',
            Cell: (x: any) => {
                return <span className="mr-4"><CryptoIcon name={x.cell.row.original.underlying.toLowerCase()} className='w-4 inline-block mb-1'/><span> {x.value}</span></span>
            }
          },
          {
            Header: 'Exchange',
            accessor: 'exchange',
            Cell: (x: any) => {
                return (
                <span><ExchangeIcon name={x.value.toLowerCase()} className='w-4 inline-block mb-1'/>
                    <a href={getReferralBaseLink(x.value)} className="ml-1">{translateExchangeName(x.value)}</a>
                </span>
                )
            }
          },
          {
            Header: <span data-tip="How often the funding is paid/received to the perpetual contract.">Funding Period</span>,
            accessor: 'fundingPeriodSeconds',
            Cell: (x: any) => <span>{`${moment.duration(x.value,'seconds').asHours()} hour(s)`}</span>
          },
          {
                Header: <span data-tip="What the average funding rate has been over a historical period. Annualized for convenience.">Avg. Annualized Funding</span>,
                id: 'avgFunding',
                columns: [
                    { Header: '1h', accessor: 'annualizedAvgFundingRate1h', Cell: (x) => <ColoredPercentage value={100 * x.value} />, sortType: 'basic' },
                    { Header: '8h', accessor: 'annualizedAvgFundingRate8h', Cell: (x) => <ColoredPercentage value={100 * x.value} />, sortType: 'basic' },
                    { Header: '1d', accessor: 'annualizedAvgFundingRate24h', Cell: (x) => <ColoredPercentage value={100 * x.value} />, sortType: 'basic' },
                    { Header: '3d', accessor: 'annualizedAvgFundingRate3d', Cell: (x) => <ColoredPercentage value={100 * x.value} />, sortType: 'basic' },
                    { Header: '7d', accessor: 'annualizedAvgFundingRate7d', Cell: (x) => <ColoredPercentage value={100 * x.value} />, sortType: 'basic' },
                    { Header: '30d', accessor: 'annualizedAvgFundingRate30d', Cell: (x) => <ColoredPercentage value={100 * x.value} />, sortType: 'basic' },
                    { Header: '90d', accessor: 'annualizedAvgFundingRate90d', Cell: (x) => <ColoredPercentage value={100 * x.value} />, sortType: 'basic' },
                ]
          },
          {
            Header: <span data-tip="Derivatives can be either linear (margined in quote currency) or inverse (margined in base currency).">Type</span>,
            accessor: 'typ',
            sortType: 'basic',
            Cell: (x: any) => <span>{x.value}</span>
          },
          {
            Header: <span data-tip="The price difference between the bid and ask prices.">Spread</span>,
            accessor: 'spreadPct',
            sortType: 'basic',
            Cell: (x: any) => <span>{x.value.toFixed(2)}%</span>
          },
          {
            Header: <span data-tip="Total volume traded in last 24h in USD">24h Volume (USD)</span>,
            accessor: 'volumeUsd24h',
            sortType: 'basic',
            Cell: (x: any) => <span>${compactNumber(x.value)}</span>
          },
          {
            Header: 'Actions',
            Cell: (x: any) => {
                const row = x.cell.row.original
                return <span>
                    <Link className='no-underline' to={`/funding?exchange=${row.exchange}&symbol=${row.symbol}`}> <img src='/img/icons/chart-area-solid.svg' className='w-4 inline-block mr-1' alt='Funding History' title='Funding History'/></Link>
                    <a className='no-underline cursor-pointer' href={row.tradeLink}> <img src='/img/icons/link-solid.svg' className='w-4 inline-block' alt='Link' title='Link'/></a>
                </span>
            }
          },
          {
            Header: 'Last Updated',
            accessor: 'updatedAt',
            Cell: (x: any) => {
                return <span>{x.value}</span>
            }
          }
        ],
    [])

    const [symbolFilter, setSymbolFilter] = useQueryParam('sf',withDefault(StringParam,''))

    const data: PerpetualsRow[] = React.useMemo(
        () => {
            const t = props.data
            return t.filter((x: any) => {
                if(symbolFilter.length === 0) return true
                return (x.symbol as string).toLowerCase().includes(symbolFilter.toLowerCase())
            })
        },
        [symbolFilter,props.data]
    )
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,

        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex }
    } = useTable({ 
            columns: columns, 
            data: data,
            initialState: {
                pageSize: 30,
                sortBy: [
                    {
                        id: 'volumeUsd24h',
                        desc: true
                    }
                ]
            }
        },
        useSortBy,
        usePagination)
    
    const buttonClass = 'rounded-md bg-slate-700 disabled:text-gray-600 text-gray-200 font-bold text-lg p-1 w-8'
    
    return (
        <div className="mt-4">
            <ReactTooltip backgroundColor='#334155'/>
            <label htmlFor="symbolFilter">Symbol Filter: </label>
            <input
                className='p-0.5 text-neutral-800 rounded-sm'
                name="symbolFilter"
                value={symbolFilter}
                onChange={e => {
                    setSymbolFilter(e.target.value || '')
                }}
                placeholder={``}
                />
            <div className="w-full max-w-full overflow-scroll">
                <table {...getTableProps()} className="shadow-sm rounded-md mt-2 border-separate w-full" style={{ minWidth: '70vw'}}>
                    <thead className="bg-slate-500"> 
                    {// Loop over the header rows
                    headerGroups.map(headerGroup => (
                        // Apply the header row props
                        <tr {...headerGroup.getHeaderGroupProps()}>
                        {// Loop over the headers in each row
                        headerGroup.headers.map(column => (
                            // Apply the header cell props
                            <th {...column.getHeaderProps((column as any).getSortByToggleProps())} className="p-1 whitespace-nowrap">
                            {// Render the header
                                column.render('Header')}
                                <div className='w-4 ml-1 inline-block'>
                                    { (column as any).isSorted ? ((column as any).isSortedDesc ? '▼' : '▲') : ''}
                                </div>
                            </th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    {/* Apply the table body props */}
                    <tbody {...getTableBodyProps()}>
                    {// Loop over the table rows
                    page.map(row => {
                        // Prepare the row for display
                        prepareRow(row)
                        return (
                        // Apply the row props
                        <tr {...row.getRowProps()} className="even:bg-slate-700 odd:bg-slate-600">
                            {// Loop over the rows cells
                            row.cells.map(cell => {
                            // Apply the cell props
                            return (
                                <td {...cell.getCellProps()} className="p-1 whitespace-nowrap w-fit overflow-hidden">
                                {// Render the cell contents
                                cell.render('Cell')}
                                </td>
                            )
                            })}
                        </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            <div className='w-full mt-2 mb-4'>
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className={buttonClass}>
                {'<<'}
                </button>{' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage} className={buttonClass}>
                {'<'}
                </button>{' '}
                <button onClick={() => nextPage()} disabled={!canNextPage} className={buttonClass}>
                {'>'}
                </button>{' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className={buttonClass}>
                {'>>'}
                </button>
                <span className='ml-2'>
                    Page: {pageIndex + 1} / {pageCount}
                </span>
            </div>
        </div>
    )
   
}

export default PerpetualsTable