import Advert from '../components/Advert'
import { FundingChart } from '../components/FundingChart'

function Funding() {
    return (
        <div className="w-full mx-auto">
            <Advert />
            <h1>Funding History</h1>
            <p className="text-md text-neutral-500">Funding history for perpetual futures.</p>
            <FundingChart/>
        </div>
    )
}

export default Funding