export function compactNumber(num: number, digits = 1) {
    return Intl.NumberFormat('en-US', {
        notation: "compact",
        maximumFractionDigits: digits
    }).format(num);
}

export function translateExchangeName(exchange: string): string {
    if(exchange === 'PerpProtocol') {
        return 'Perp'
    }
    return exchange
}