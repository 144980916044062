interface ImpactPercentageProps {
    pct: number
}
export function ImpactPercentage(props: ImpactPercentageProps) {
    const pct = props.pct
    let color = 'text-green-400'
    if(pct > 0.5) {
        color = 'text-amber-400' 
    }
    if(pct > 1) {
        color = 'text-red-400' 
    }
    return <span className={color}>{`~${pct.toFixed(2)}%`}</span>
}