import { TwitterShareButton, TwitterIcon, FacebookShareButton, FacebookIcon, TelegramShareButton, TelegramIcon, WhatsappShareButton, WhatsappIcon, RedditShareButton, RedditIcon, EmailShareButton, EmailIcon } from 'react-share'
import CopyTextButton from './CopyTextButton'
import ReactGA from 'react-ga'

function sendShareEvent(name: string) {
    ReactGA.event({
      action: name,
      category: 'share',
    })
}

function ShareButtons() {
    const size = 26
    const url = window.location.href
    return  <span>
              <CopyTextButton size={size} url={url} />
              <TwitterShareButton onClick={() => sendShareEvent('twitter')} className='mr-1' children={<TwitterIcon size={size} round/>} url={url}/>
              <FacebookShareButton onClick={() => sendShareEvent('facebook')} className='mr-1' children={<FacebookIcon size={size} round/>} url={url}/>
              <TelegramShareButton onClick={() => sendShareEvent('telegram')} className='mr-1' children={<TelegramIcon size={size} round/>} url={url}/>
              <WhatsappShareButton onClick={() => sendShareEvent('whatsapp')} className='mr-1' children={<WhatsappIcon size={size} round/>} url={url}/>
              <RedditShareButton onClick={() => sendShareEvent('reddit')} className='mr-1' children={<RedditIcon size={size} round/>} url={url}/>
              <EmailShareButton onClick={() => sendShareEvent('email')} className='mr-1' children={<EmailIcon size={size} round/>} url={url}/>
            </span>
}

export default ShareButtons
