import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import DarkUnica from "highcharts/themes/dark-unica";
import { useEffect, useState } from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { fetchBasis, fetchFuturesStub } from "../api";

export const SECONDS_IN_DAY = 24 * 60 * 60;
const DEFAULT_EXCHANGE = "Binance";
const DEFAULT_SYMBOL = "";

const DEFAULT_CHART: any = {
  chart: {
    backgroundColor: "#334155",
    borderRadius: "0.5rem",
  },
};

export function BasisChart() {
  const [loadingStub, setLoadingStub] = useState(true);
  const [stub, setStub] = useState({} as any);
  const [exchange, setExchange] = useQueryParam(
    "exchange",
    withDefault(StringParam, DEFAULT_EXCHANGE)
  );
  const [symbol, setSymbol] = useQueryParam(
    "symbol",
    withDefault(StringParam, DEFAULT_SYMBOL)
  );

  const [options, setOptions] = useState(DEFAULT_CHART);

  DarkUnica(Highcharts);

  useEffect(() => {
    const getData = async () => {
      const data = await fetchFuturesStub();
      setStub(data);
      setLoadingStub(false);
      if (exchange === "" || symbol === "") {
        const exch = Object.keys(data)[0];
        setExchange(exch);
        setSymbol(data[exch][0]);
      }
    };
    getData();
  }, [exchange, symbol, setSymbol, setExchange]);

  useEffect(() => {
    if (exchange === "" || symbol === "") {
      return;
    }
    const getData = async () => {
      const end = Math.ceil(new Date().getTime() / 1000);
      const start = end - 90 * SECONDS_IN_DAY;
      const data = await fetchBasis(exchange, symbol, start, end);
      const formatted = data.map((x: any) => [
        new Date(x.timestamp).getTime(),
        100 * x.basisFrac,
      ]);
      const yMin = Math.min(0, ...formatted.map((x: any) => x[1]));
      const yMax = Math.max(0, ...formatted.map((x: any) => x[1]));

      setOptions({
        ...DEFAULT_CHART,
        series: [
          {
            name: "Basis (%)",
            data: formatted,
            color: "#fcd34d",
          },
        ],
        yAxis: {
          title: {
            text: "Basis (%)",
          },
          plotLines: [
            {
              value: 0,
              width: 3,
              color: "#0284c7",
            },
          ],
          max: yMax,
          min: yMin,
        },
        xAxis: {
          type: "datetime",
          ordinal: false,
        },
      });
    };
    getData();
  }, [exchange, symbol]);

  if (loadingStub) {
    return <div className="mt-4"></div>;
  }

  const exchangeSyms = stub[exchange] || [];

  return (
    <div className="mt-4">
      <div className="flex flex-col lg:flex-row">
        <div>
          <label htmlFor="exchangeLookup">Exchange: </label>
          <select
            className="p-0.5 text-neutral-800 rounded-sm mr-2 mb-2"
            name="exchangeLookup"
            value={exchange}
            onChange={(e) => {
              setExchange(e.target.value);
              setSymbol(stub[e.target.value][0]);
            }}
          >
            {Object.keys(stub).map((x: string) => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="symbolLookup">Symbol: </label>
          <select
            className="p-0.5 text-neutral-800 rounded-sm mr-2 mb-2"
            name="symbolLookup"
            value={symbol}
            onChange={(e) => {
              setSymbol(e.target.value);
            }}
          >
            {exchangeSyms.map((x: string) => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
          </select>
        </div>
      </div>

      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={options}
      />
    </div>
  );
}
