import Advert from '../components/Advert'
import { BasisChart } from '../components/BasisChart'

function Basis() {
    return (
        <div className="w-full mx-auto">
            <Advert />
            <h1>Basis History</h1>
            <p className="text-md text-neutral-500">Basis history for fixed date futures.</p>
            <BasisChart/>
        </div>
    )
}

export default Basis