import ReactGA from 'react-ga';

function sendAdEvent() {
    ReactGA.event({
      action: 'click',
      category: 'advert',
    })
}

function Advert() {
    // return (
    //     <div 
    //         className='h-16 p-2 mb-2 lg:w-1/2 w-full bg-slate-800 mx-auto flex cursor-pointer' 
    //         onClick={() => { 
    //                 window.location.href = 'https://pdgm.co/3Vv4DD0'; 
    //                 return null;
    //         }}>
    //         <img src='/img/paradigm_ad.png' className='h-full'/>
    //         <div className='ml-4 font-semibold'>
    //             Trade basis with NO leg risk AND 50% off exchange fees. Only on Paradigm.
    //         </div>
    //     </div>
    // )
    return (
        <div className='mb-4 lg:w-1/2 w-full bg-slate-800 mx-auto rounded-md overflow-hidden'>
            <a href='https://pdgm.co/3Vv4DD0' target='_blank' rel='noreferrer' onClick={() => sendAdEvent()}>
                <img src='/img/paradigm.png' alt='Paradigm ad'/>
            </a>
        </div>
    )
}

export default Advert