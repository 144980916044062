import ReactGA from 'react-ga'

function copy(text: string) {
    const el = document.createElement('input')
    el.value = text
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
}

function sendShareEvent(name: string) {
    ReactGA.event({
      action: name,
      category: 'share',
    })
}
  
function CopyTextButton({ size, url }: any) {
    return <button className='bg-neutral-400 p-1 mr-1 rounded-full align-top'>
        <img width={size-8} height={size-8} src='/img/icons/copy.svg' alt='copy to clipboard' onClick={() => {
        copy(url)
        alert('Copied to clipboard')
        sendShareEvent('copy')
        }}/>
    </button>
}

export default CopyTextButton