import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

function Home() {
    const [tradeType,setTradeType] = useState('All')
    const [currency,setCurrency] = useState('USD')
    const [notional,setNotional] = useState(1_000)

    const navigate = useNavigate()

    return (
        <div className="w-full mx-auto">

            <div className="flex w-full mx-auto text-left lg:mt-0 mt-10">
                <img alt='Rocket' src="./img/rocket.png" className="w-1/3 hidden lg:flex"/>
                <div className="relative inline-flex items-center mx-auto align-middle">
                    <div className="text-center">
                        <h1 className="text-6xl font-bold leading-none tracking-tighter text-neutral-300 lg:text-8xl">
                            Be Your Own <span className='text-amber-400'>Yield</span>
                        </h1>
                        {/* <h1 className="max-w-5xl mt-4 text-xl font-bold leading-none tracking-tighter text-neutral-300 lg:text-2xl lg:max-w-7xl">
                            Find Profitable Crypto Basis Trades
                        </h1> */}
                        <div className='flex flex-row relative'>
                            <img alt='Rocket' src="./img/rocket.png" className="flex lg:hidden absolute -z-10 opacity-20" style={{aspectRatio: '1 / 1'}}/>
                            <p className="max-w-xl mx-auto mt-12 text-base text-justify leading-relaxed text-gray-400">
                                Basis trading is a technique of buying spot and selling a future or perpetual to generate yield, with <span className='font-bold text-lg text-gray-300'>no price risk</span>. We monitor multiple CeFi
                                and DeFi exchanges looking for potentially profitable trades taking into account <span className='font-bold text-gray-300'>fees</span>, <span className='font-bold text-gray-300'>spreads</span> and <span className='font-bold text-gray-300'>liquidity</span>. 
                                Read more <Link to="/about">here</Link>...
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex lg:w-1/2 md:w-full lg:mt-2 mt-12 mx-auto text-left p-4 bg-slate-600 rounded-xl">
                <div className="relative inline-flex items-center mx-auto align-middle">
                    <div className="text-center text-3xl space-y-3">
                        I want {tradeType === 'All' ? '' : 'a'} 
                        <select className="mr-2 ml-2 text-gray-700 bg-neutral-300"
                            onChange={e => {
                                setTradeType(e.target.value)
                            }}
                            value={tradeType}
                        >
                            <option value="All">any trade</option>
                            <option value="VariableBasis">variable basis trade</option>
                            <option value="FixedBasis">fixed basis trade</option>
                            <option value="FundingArb">funding arb trade</option>
                        </select>
                        for a notional of
                        <select className="mr-2 ml-2 text-gray-700 bg-neutral-300"
                            onChange={e => {
                                setNotional(Number.parseInt(e.target.value))
                            }}
                            value={notional}
                        >
                            <option value="1000">1,000</option>
                            <option value="10000">10,000</option>
                            <option value="100000">100,000</option>
                            <option value="1000000">1,000,000</option>
                            <option value="10000000">10,000,000</option>
                        </select>
                        <select className="mr-2 ml-2 text-gray-700 bg-neutral-300"
                            onChange={e => {
                                setCurrency(e.target.value)
                            }}
                            value={currency}
                        >
                            <option value="USD">USD</option>
                            <option value="USDT">USDT</option>
                        </select>
                        <br/>
                        <button className="bg-yellow-500 text-gray-800 rounded-md p-2 mt-1 hover:bg-yellow-500"
                        onClick={e => {
                            navigate(`/trades?type=${tradeType}&currency=${currency}&notional=${notional}`)
                        }}>Find Trades!</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home