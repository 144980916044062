import axios from 'axios'
import moment from 'moment'

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://api.cryptoncarry.com' : 'http://localhost:8080'
const SECONDS_IN_YEAR = 365 * 24 * 60 * 60

export async function fetchFutures() {
    const ret = await axios.get(`${BASE_URL}/future`)
    return formatFuturesData(ret.data)
}

function formatFuturesData(data: any[]): any[] {
    const now = moment()
    return data.map((x: any) => {
        const timeTilExpiry = moment.duration(moment(x.expiryTimestamp).diff(now))
        return {
            symbol: x.symbol,
            underlying: x.underlying,
            exchange: x.exchange.name,
            pctBasis: 100 * x.basisFrac,
            settlesIn: timeTilExpiry.asDays(),
            pctAnnualizedBasis: 100 * x.basisFrac / (timeTilExpiry.asSeconds()/SECONDS_IN_YEAR),
            spreadPct: 100 * x.spreadFrac,
            volumeUsd24h: x.volumeUsd24h,
            tradeLink: x.tradeLink,
            typ: x.isInverse ? 'Inverse' : 'Linear',
            updatedAt: moment(x.updatedAt).fromNow()
        }
    })
}

export async function fetchFuturesStub() {
    const ret = await axios.get(`${BASE_URL}/future`,{
        params: {
            stub: true
        }
    })
    const data = ret.data
    const map: any = {}
    for(const d of data) {
        const exchange = d.exchange.name
        const symbol = d.symbol
        if(exchange in map) {
            map[exchange].push(symbol)
        } else {
            map[exchange] = [symbol]
        }
    }
    for(const k in map) {
        map[k].sort()
    }
    return map
}

export async function fetchTrades() {
    const ret = await axios.get(`${BASE_URL}/trades`, {
    })
    return formatTradesData(ret.data)
}

function formatTradesData(data: any[]): any[] {
    return data.map((x: any) => {
        const obj: any = {
            id: x.id,
            notional: x.notional,
            currency: x.quoteCurrency,
            tradeType: x.type,        
            return: 100 * x.payload.return,
            annualizedReturn: 100 * x.payload.annualizedReturn,
            tradeDurationDays: x.payload.holdTimeDays
        }
        if(['FixedBasis','VariableBasis'].includes(obj.tradeType)) {
            obj.leg1 = x.payload.spotLeg
            obj.leg2 = x.payload.futureLeg || x.payload.perpetualLeg
        }
        if(obj.tradeType === 'FundingArb') {
            obj.leg1 = x.payload.longPerpetualLeg
            obj.leg2 = x.payload.shortPerpetualLeg
        }
        return obj
    })
}

export async function fetchTrade(id: string) {
    const ret = await axios.get(`${BASE_URL}/trade/${id}`)
    return ret.data
}

export async function fetchFunding(exchange: string, symbol: string, startTime: number, endTime: number): Promise<any[]> {
    const ret = await axios.get(`${BASE_URL}/funding`,{
        params: {
            exchange: exchange,
            symbol: symbol,
            startTime: startTime,
            endTime: endTime
        }
    })
    return ret.data
}

export async function fetchPerpetualStub() {
    const ret = await axios.get(`${BASE_URL}/perpetual`, {
        params: {
            stub: true
        }
    })
    const data = ret.data
    const map: any = {}
    for(const d of data) {
        const exchange = d.exchange.name
        const symbol = d.symbol
        if(exchange in map) {
            map[exchange].push(symbol)
        } else {
            map[exchange] = [symbol]
        }
    }
    for(const k in map) {
        map[k].sort()
    }
    return map
}

export async function fetchPerpetuals() {
    const ret = await axios.get(`${BASE_URL}/perpetual`)
    return formatPerpetualsData(ret.data)
}

function formatPerpetualsData(data: any[]): any[] {
    return data.map((x: any) => {
        return {
            symbol: x.symbol,
            underlying: x.underlying,
            exchange: x.exchange.name,
            fundingPeriodSeconds: x.fundingPeriodSeconds,
            annualizedAvgFundingRate1h: x.annualizedAvgFundingRate1h,
            annualizedAvgFundingRate8h: x.annualizedAvgFundingRate8h,
            annualizedAvgFundingRate24h: x.annualizedAvgFundingRate24h,
            annualizedAvgFundingRate3d: x.annualizedAvgFundingRate3d,
            annualizedAvgFundingRate7d: x.annualizedAvgFundingRate7d,
            annualizedAvgFundingRate30d: x.annualizedAvgFundingRate30d,
            annualizedAvgFundingRate90d: x.annualizedAvgFundingRate90d,
            tradeLink: x.tradeLink,
            spreadPct: 100 * x.spreadFrac,
            volumeUsd24h: x.volumeUsd24h,
            typ: x.isInverse ? 'Inverse' : 'Linear',
            updatedAt: moment(x.updatedAt).fromNow()
        }
    })
}

export async function fetchBasis(exchange: string, symbol: string, startTime: number, endTime: number): Promise<any[]> {
    const ret = await axios.get(`${BASE_URL}/basis`,{
        params: {
            exchange: exchange,
            symbol: symbol,
            startTime: startTime,
            endTime: endTime
        }
    })
    return ret.data
}

export async function fetchCollateral() {
    const ret = await axios.get(`${BASE_URL}/collateral`)
    return ret.data.map((x: any) => {
        return {
            symbol: x.symbol,
            exchange: x.exchange.name,
            weight: x.weight,
            weightInitial: x.weightInitial,
            updatedAt: moment(x.updatedAt).fromNow()
        }
    })
}
