import Advert from '../components/Advert'

function About() {
    return (
        <div className="w-full mx-auto">
            <Advert />
            <h1>About</h1>
            <h2 className='mt-4'>What is a Basis Trade?</h2>
            <p className='mt-2 text-neutral-400'>
                A basis trade is a delta neutral trade consisting of two legs. The trader goes long a coin, and then sells short a future or perpetual contract with the same coin as the underlying.
                Due to the offseting nature of the two positions, the trade should have no price risk. This means that any change in the price of the coin will have no effect on the combined portfolio.
            </p>
            <p className='mt-2 text-neutral-400'>
               cryptoncarry.com keeps track of two types of trades:
            </p>
            <h3 className='mt-4'>Fixed Rate (Future)</h3>
            <p className='mt-2 text-neutral-400'>
               The trader goes long spot, and short a future with a positive basis, and holds the trade until expiry. This in effect "locks" in the basis rate as a return.
            </p>
            <video className='mt-2 lg:w-1/2 w-full' src='/img/fixedbasis.mp4' controls muted autoPlay loop/>
            <h3 className='mt-4'>Variable Rate (Perpetual)</h3>
            <p className='mt-2 text-neutral-400'>
               The trader goes long spot, and short a perpetual. The perpetual is chosen based on its funding history, and anticipated future funding payments. Assuming the funding rate is overall positive, then
               the trade will make a consistent positive return. However, since the funding rate is variable this trade does not guarantee a fixed return, and therefore is considered variable.
            </p>
            <video className='mt-2 lg:w-1/2 w-full' src='/img/variablebasis.mp4' controls muted autoPlay loop/>

            <h2 className='mt-4'>What are Futures, Perpetuals, Basis Trades, etc?</h2>
            <p className='mt-2 text-neutral-400'>
                We suggest reading the <a href="https://www.machow.ski/posts/crypto_and_carry">following article</a> for an in depth guide.
            </p>

            <h2 className='mt-4'>How Trades are Calculated</h2>
            <p className='mt-2 text-neutral-400'>
                This site makes several assumptions in the calculation of potential basis trades. Due to limitiations such as the lack of fully real time data, certain simplications are also used.
                The specifics of the methods are outlined below.
            </p>
            <h3 className='mt-4'>Margin & Collateral</h3>
            <p className='mt-2 text-neutral-400'>
                We assume that the trader will ideally use little to no leverage. In addition, where possible trades are margined using the underlying spot currency that was purchased to improve efficiency.
            </p>
            <ul className='list-disc mt-2 pl-2 text-neutral-400'>
                <li>For exchanges that use cross-asset margining, we assume that the trader will top up their account with collateral to maintain 1x leverage. For instance, a $1,000 trade for BTC that has an 80% haircut assumes that an extra $200 will be added to fund the derivatives leg, reducing the return. Not doing this will increase the return, but also increase leverage leading to liquidation risk.</li>
                <li>For inverse products, the collateral is assumed to be used for the derivatives trade 1 to 1.</li>
                <li>For exchanges that cannot use the underlying as collateral in any way, we assume the derivatives leg is fully funded by cash.</li>
            </ul>

            <h3 className='mt-4'>Fixed Basis Trade</h3>
            <ul className='list-disc mt-2 pl-2 text-neutral-400'>
                <li>The trader is assumed to buy a coin using a spot market, and simultaneously sell a future short.</li>
                <li>The trader is assumed to hold the future till expiry, thereby guaranteeing the locked basis.</li>
                <li>Due to price latency, it is assumed that the spot position is entered at the price of the underyling and the future is entered at the mark price. This is not entirely accurate as depending on the exchange, the actual mid price might be slightly different.</li>
                <li>The trader is assumed to be aggressive on both legs, and pays the taker fee.</li>
                <li>Half the spot and future spread is subtracted from the return to simulate the cost of being aggressive and crossing the spread.</li>
                <li>The slippage is estimated by calculating the average fill price for the specific quantity using periodically snapped order books.</li>
                <li>The final return less all of the above deductions is the final return, and is annualised for convenience.</li>
            </ul>
            <h3 className='mt-4'>Variable Basis Trade</h3>
            <ul className='list-disc mt-2 pl-2 text-neutral-400'>
                <li>The trader is assumed to buy a coin using a spot market, and simultaneously sell a perpetual short.</li>
                <li>The trader is assumed to hold the trade for <strong>7 days</strong>, this is done for simplicity.</li>
                <li>The last 7 days of funding is averaged and assumed to be the prevailing rate for the duration of the trade.</li>
                <li>The total return is calculated to be the average 7 day funding rate multiplied by the number of funding events in the 7 day period.</li>
                <li>The trader is assumed to be aggressive on both legs, and pays the taker fee.</li>
                <li>Half the spot and future spread is subtracted from the return to simulate the cost of being aggressive and crossing the spread.</li>
                <li>The slippage is estimated by calculating the average fill price for the specific quantity using periodically snapped order books.</li>
                <li>The final return less all of the above deductions is the final return, and is annualised for convenience.</li>
            </ul>
            <h2 className='mt-4'>Contact Us</h2>
            <p className='mt-2 text-neutral-400'>
                For enquiries, feedback or bug reports please email: <a href="mailto:cryptoncarry@gmail.com">cryptoncarry@gmail.com</a>.
            </p>
        </div>
    )
}

export default About