interface PropParams {
    type: 'FixedBasis' | 'VariableBasis' | 'FundingArb'
}

export function BasisTypeIcon(props: PropParams) {
    let color = 'bg-amber-400'
    let text = 'fixed'
    if(props.type === 'VariableBasis') {
        color = 'bg-indigo-400'
        text = 'variable'
    }
    if(props.type === 'FundingArb') {
        color = 'bg-sky-400'
        text = 'funding arb'
    }
    return (
        <div className={`rounded-md p-1 text-neutral-800 w-3/4 min-w-min text-center whitespace-nowrap ${color}`}>{text}</div>
    )
}