import { Link } from 'react-router-dom'
import Advert from '../components/Advert'
import TradesTable from '../components/TradesTable'

function Trades() {
    return (
        <div className="w-full mx-auto">
            <Advert />
            <h1>Trade Ideas</h1>
            <p className="text-md text-neutral-500">Click the trade row to find out more details about the trade. All returns are estimated. For more info about basis trades, click <Link to="/about">here</Link>.</p>
            <TradesTable />
        </div>
    )
}

export default Trades