import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { fetchPerpetuals } from '../api'
import Advert from '../components/Advert'
import PerpetualsTable from '../components/PerpetualsTable'

function Perpetuals() {
    const [perpsData,setPerpsData] = useState([] as any[])
    useEffect(() => {
      const getData = async () => {
        const data = await fetchPerpetuals()
        setPerpsData(data)
      }
      getData()
    },[])
    return (
        <div className="w-full mx-auto">
            <Advert />
            <h1>Perpetuals</h1>
            <p className="text-sm text-neutral-500">Details of all perpetual futures being tracked. Find out more about perpetual futures <Link to="/about">here</Link></p>
            <PerpetualsTable data={perpsData}/>
        </div>
    )
}

export default Perpetuals