import { useState } from 'react'
import { Link } from 'react-router-dom'
import NavItem from './NavItem'

const links = []
links.push({ text: 'Futures', to: '/futures'})
links.push({ text: 'Perpetuals', to: '/perpetuals'})
links.push({ text: 'Funding History', to: '/funding'})
links.push({ text: 'Basis History', to: '/basis'})
links.push({ text: 'About', to: '/about'})

function Navbar() {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const closeMenu = (e: any) => {
    setNavbarOpen(false)
  }
  return (
    <div className="xl:container w-full">
      <div className="flex flex-col p-5 mx-auto xl:flex-row w-full xl:px-8">
        <div className="flex flex-row justify-between xl:justify-start">
          <div className='flex flex-row'>
            <Link to="/" className='no-underline'>
              {/* <img src='/img/logo.png' style={{width: '265px'}}/> */}
              <span className='text-2xl text-amber-400 font-bold' style={{textShadow: '2px 2px #92400e'}}>CRYPTO N' CARRY</span>
            </Link>
          <div className='bg-amber-500 p-0.5 rounded-md text-xs h-fit ml-2 text-neutral-800 font-bold'>beta</div>
          </div>
          <button
              className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block xl:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <img src='/img/bars.svg' alt="Crypto n' Carry Logo" className='w-6 h-6'/>
          </button>
        </div>
        <nav className={"xl:flex flex-col flex-grow xl:justify-start xl:flex-row " + (navbarOpen ? 'flex' : 'hidden')}>
          <ul className="space-y-2 list-none md:space-y-0 xl:items-center xl:inline-flex">
            <NavItem onClick={closeMenu} text="Trade Ideas" to="/trades"/>
            <span className='hidden xl:flex'>|</span>
            <NavItem onClick={closeMenu} text="Futures" to="/futures"/>
            <span className='hidden xl:flex'>|</span>
            <NavItem onClick={closeMenu} text="Perpetuals" to="/perpetuals"/>
            <span className='hidden xl:flex'>|</span>
            <NavItem onClick={closeMenu} text="Funding History" to="/funding"/>
            <span className='hidden xl:flex'>|</span>
            <NavItem onClick={closeMenu} text="Basis History" to="/basis"/>
            <span className='hidden xl:flex'>|</span>
            <NavItem onClick={closeMenu} text="Collateral" to="/collateral"/>
            <span className='hidden xl:flex'>|</span>
            <NavItem onClick={closeMenu} text="About" to="/about"/>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Navbar;
