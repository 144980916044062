import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Column, usePagination, useSortBy, useTable } from 'react-table'
import ReactTooltip from 'react-tooltip'
import { BooleanParam, NumberParam, StringParam, useQueryParam, withDefault } from 'use-query-params'
import { fetchTrades } from '../api'
import { BasisTypeIcon } from './BasisTypeIcon'

const TRADE_CURRENCIES = ['USD','USDT']
const TRADE_NOTIONALS = [1_000,10_000,100_000,1_000_000,10_000_000]
interface TradeRow {
    id: string
    tradeType: string
    notional: number
    currency: string
    leg1: any
    leg2: any
    return: number
    annualizedReturn: number
    tradeDurationDays: number
}

function TradesTable(props: any) {
    const columns: Column<TradeRow>[] = React.useMemo(
        () => [
          {
            Header: <span data-tip="The type of trade (fixed rate, variable rate, etc)">Trade Type</span>,
            accessor: 'tradeType',
            Cell: (x: any) => <BasisTypeIcon type={x.value} />
          },
          {
            Header: <span data-tip="The amount of currency that is required to carry out the trade.">Notional</span>,

            accessor: 'notional',
            sortType: 'basic',
            Cell: (x: any) => <span>{x.value.toLocaleString()}</span>
          },
          {
            Header: <span data-tip="The currency used for executing this trade.">Currency</span>,
            accessor: 'currency'
          },
          {
            Header: <span data-tip="The first leg of this trade.">Leg 1</span>,
            accessor: 'leg1',
            Cell: (x: any) => <span><span className={`font-bold ${x.value.side === 'buy' ? 'text-green-500' : 'text-red-500'}`}>{x.value.side.toUpperCase()}</span><span> [{x.value.exchange}] {x.value.symbol}</span></span>
          },
          {
            Header: <span data-tip="The second leg of this trade.">Leg 2</span>,
            accessor: 'leg2',
            Cell: (x: any) => <span><span className={`font-bold ${x.value.side === 'buy' ? 'text-green-500' : 'text-red-500'}`}>{x.value.side.toUpperCase()}</span><span> [{x.value.exchange}] {x.value.symbol}</span></span>
          },
          {
            Header: <span data-tip="How long the trade will be held for.">Duration</span>,
            accessor: 'tradeDurationDays',
            Cell: (x: any) => <span>{`${x.value.toFixed(1)} days`}</span>
          },
          {
            Header: <span data-tip="The approximate percentage return of carrying out this trade.">% Return</span>,
            accessor: 'return',
            sortType: 'basic',
            Cell: (x: any) => <span className={x.value > 0 ? 'text-green-500' : 'text-red-500'}>{`${x.value.toFixed(2)}%`}</span>
          },
          {
            Header: <span data-tip="The Annualized Percentage Return of this trade. Used for comparing trades with different hold times.">% APR</span>,
            accessor: 'annualizedReturn',
            sortType: 'basic',
            Cell: (x: any) => <span className={x.value > 0 ? 'text-green-500' : 'text-red-500'}>{`${x.value.toFixed(2)}%`}</span>
          },
          {
            Header: 'Details',
            accessor: 'id',
            Cell: (x: any) => <Link to={`/trade/${x.value}`}>details</Link>
          }
        ],
    [])

    const [tradeTypeFilter, setTradeTypeFilter] = useQueryParam('type',withDefault(StringParam,'All'))
    const [notionalFilter, setNotionalFilter] = useQueryParam('notional',withDefault(NumberParam,TRADE_NOTIONALS[0]))
    const [currencyFilter, setCurrencyFilter] = useQueryParam('currency',withDefault(StringParam,TRADE_CURRENCIES[0]))
    const [sameExchangeFilter, setSameExchangeFilter] = useQueryParam('sameExch',withDefault(BooleanParam,true))
    // const [unprofitableFilter, setUnprofitableFilter] = useQueryParam('showUnprofitable',withDefault(BooleanParam,false))

    const [tradeData,setTradeData] = useState([] as any[])

    useEffect(() => {
      const getData = async () => {
        setTradeData([])
        const data = await fetchTrades()
        setTradeData(data)
      }
      getData()
    },[])

    const navigate = useNavigate();
    
    const data: TradeRow[] = React.useMemo(
        () => {
            const t = tradeData
            return t.filter((x: any) => {
                if(tradeTypeFilter === 'All') return true
                return x.tradeType === tradeTypeFilter
            })
            .filter((x: any) => {
                return x.notional === notionalFilter
            })
            .filter((x: any) => {
                return x.currency === currencyFilter
            })
            .filter((x: any) => {
                // We always show funding arb, as it can only be for different exchanges
                // TODO: This is dumb
                if(x.tradeType === 'FundingArb') {
                    return true
                }
                if(sameExchangeFilter) {
                    if(x.leg1 !== undefined && x.leg2 !== undefined) {
                        return x.leg1.exchange === x.leg2.exchange
                    } else {
                        return false
                    }
                }
                return true
            })
        },
        [
            tradeTypeFilter,
            notionalFilter,
            currencyFilter,
            sameExchangeFilter,
            tradeData
        ]
      )
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,

        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex }
    } = useTable({ 
          columns: columns, 
          data: data,
          initialState: {
              pageSize: 30
          }
        },
        useSortBy,
        usePagination)
    
    const buttonClass = 'rounded-md bg-slate-700 disabled:text-gray-600 text-gray-200 font-bold text-lg p-1 w-8'
    
    return (
        <div className="mt-4">
            <ReactTooltip backgroundColor='#334155'/>

            <div className='flex flex-col lg:flex-row'>
                <div>
                    <label htmlFor="tradeTypeFilter">Trade Type: </label>
                    <select
                        className='p-0.5 text-neutral-800 rounded-sm mr-2 mb-2'
                        name="tradeTypeFilter"
                        value={tradeTypeFilter}
                        onChange={e => {
                            setTradeTypeFilter(e.target.value)
                        }}
                        >
                        <option value="All">All</option>
                        <option value="FixedBasis">Fixed Basis</option>
                        <option value="VariableBasis">Variable Basis</option>
                        <option value="FundingArb">Funding Arb</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="notionalFilter">Notional: </label>
                    <select
                        className='p-0.5 text-neutral-800 rounded-sm mr-2 mb-2'
                        name="notionalFilter"
                        value={notionalFilter}
                        onChange={e => {
                            setNotionalFilter(Number.parseInt(e.target.value))
                        }}
                        >
                        {TRADE_NOTIONALS.map((notional: number) => <option key={notional} value={notional}>{notional.toLocaleString()}</option>)}
                    </select>
                </div>
                <div>
                    <label htmlFor="currencyFilter">Currency: </label>
                    <select
                        className='p-0.5 text-neutral-800 rounded-sm mr-2 mb-2'
                        name="currencyFilter"
                        value={currencyFilter}
                        onChange={e => {
                            setCurrencyFilter(e.target.value)
                        }}
                        >
                        {TRADE_CURRENCIES.map((ccy: string) => <option key={ccy} value={ccy}>{ccy.toLocaleString()}</option>)}
                    </select>
                </div>
                <div>
                    <label htmlFor="sameExchangeFilter">Same Exchange: </label>
                    <input
                        type="checkbox"
                        className='rounded-sm mr-2 mb-2 w-4 h-4'
                        name="sameExchangeFilter"
                        checked={sameExchangeFilter}
                        onChange={e => {
                            setSameExchangeFilter(e.target.checked)
                        }}
                        />
                </div>
                {/* <div>
                    <label htmlFor="unprofitableFilter">Show Unprofitable: </label>
                    <input
                        type="checkbox"
                        className='rounded-sm mr-2 mb-2 w-4 h-4'
                        name="unprofitableFilter"
                        checked={unprofitableFilter}
                        onChange={e => {
                            setUnprofitableFilter(e.target.checked)
                        }}
                        />
                </div> */}
            </div>

            <div className="w-full max-w-full overflow-scroll">
                <table {...getTableProps()} className="shadow-sm rounded-md mt-2 border-separate w-full" style={{ minWidth: '70vw'}}>
                    <thead className="bg-slate-500"> 
                    {// Loop over the header rows
                    headerGroups.map(headerGroup => (
                        // Apply the header row props
                        <tr {...headerGroup.getHeaderGroupProps()}>
                        {// Loop over the headers in each row
                        headerGroup.headers.map(column => (
                            // Apply the header cell props
                            <th {...column.getHeaderProps((column as any).getSortByToggleProps())} className="p-1.5 whitespace-nowrap">
                            {// Render the header
                                column.render('Header')}
                                <div className='w-4 ml-1 inline-block'>
                                    { (column as any).isSorted ? ((column as any).isSortedDesc ? '▼' : '▲') : ''}
                                </div>
                            </th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    {/* Apply the table body props */}
                    <tbody {...getTableBodyProps()}>
                    {// Loop over the table rows
                    page.map(row => {
                        // Prepare the row for display
                        prepareRow(row)
                        return (
                        // Apply the row props
                        <tr {...row.getRowProps()} className="even:bg-slate-700 odd:bg-slate-600 hover:bg-slate-500 cursor-pointer" onClick={() => navigate(`/trade/${row.original.id}`)}>
                            {// Loop over the rows cells
                            row.cells.map(cell => {
                            // Apply the cell props
                            return (
                                <td {...cell.getCellProps()} className="p-1.5 whitespace-nowrap">
                                {// Render the cell contents
                                cell.render('Cell')}
                                </td>
                            )
                            })}
                        </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            <div className='w-full mt-2 mb-4'>
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className={buttonClass}>
                {'<<'}
                </button>{' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage} className={buttonClass}>
                {'<'}
                </button>{' '}
                <button onClick={() => nextPage()} disabled={!canNextPage} className={buttonClass}>
                {'>'}
                </button>{' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className={buttonClass}>
                {'>>'}
                </button>
                <span className='ml-2'>
                    Page: {pageIndex + 1} / {pageCount}
                </span>
            </div>
        </div>
    )
   
}

export default TradesTable
