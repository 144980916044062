import React from 'react'
import { Column, usePagination, useSortBy, useTable } from 'react-table'
import ReactTooltip from 'react-tooltip'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import { getReferralBaseLink } from '../referral'
import { CryptoIcon } from './CryptoIcon'
import { ExchangeIcon } from './ExchangeIcon'

interface CollateralRow {
    symbol: string
    exchange: string
    weight: number
    weightInitial: number
    updatedAt: Date
}

function FuturesTable(props: any) {
    const columns: Column<CollateralRow>[] = React.useMemo(
        () => [
          {
            Header: 'Symbol',
            accessor: 'symbol',
            Cell: (x: any) => {
                return <span className="mr-4"><CryptoIcon name={x.value.toLowerCase()} className='w-4 inline-block mb-1'/><span> {x.value}</span></span>
            }
          },
          {
            Header: 'Exchange',
            accessor: 'exchange',
            Cell: (x: any) => {
                return (
                <span><ExchangeIcon name={x.value.toLowerCase()} className='w-4 inline-block mb-1'/>
                    <a href={getReferralBaseLink(x.value)} className="ml-1">{x.value}</a>
                </span>
                )
            }
          },
          {
            Header: <span data-tip="Weight of the collateral while holding a position">Weight</span>,
            accessor: 'weight',
            sortType: 'basic',
            Cell: (x: any) => <span>{x.value.toFixed(3)}</span>
          },
          {
            Header: <span data-tip="Weight of the collateral when opening a position">Weight (Initial)</span>,
            accessor: 'weightInitial',
            sortType: 'basic',
            Cell: (x: any) => <span>{x.value.toFixed(3)}</span>
          },
          {
            Header: 'Last Updated',
            accessor: 'updatedAt',
            Cell: (x: any) => {
                return <span>{x.value}</span>
            }
          }
        ],
    [])

    const [symbolFilter, setSymbolFilter] = useQueryParam('sf',withDefault(StringParam,''))

    const data: CollateralRow[] = React.useMemo(
        () => {
            const t = props.data
            return t.filter((x: any) => {
                if(symbolFilter.length === 0) return true
                return (x.symbol as string).toLowerCase().includes(symbolFilter.toLowerCase())
            })
        },
        [symbolFilter,props.data]
      )
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,

        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex }
    } = useTable({ 
            columns: columns, 
            data: data,
            initialState: {
                pageSize: 30,
                sortBy: [
                    {
                        id: 'volumeUsd24h',
                        desc: true
                    }
                ]
            }
        },
        useSortBy,
        usePagination)
    
    const buttonClass = 'rounded-md bg-slate-700 disabled:text-gray-600 text-gray-200 font-bold text-lg p-1 w-8'
    
    return (
        <div className="mt-4">
            <ReactTooltip backgroundColor='#334155'/>
            <label htmlFor="symbolFilter">Symbol Filter: </label>
            <input
                className='p-0.5 text-neutral-800 rounded-sm'
                name="symbolFilter"
                value={symbolFilter}
                onChange={e => {
                    setSymbolFilter(e.target.value || '')
                }}
                placeholder={``}
                />
            <div className="w-full max-w-full overflow-scroll">
                <table {...getTableProps()} className="shadow-sm rounded-md mt-2 border-separate w-full" style={{ minWidth: '70vw'}}>
                    <thead className="bg-slate-500"> 
                    {// Loop over the header rows
                    headerGroups.map(headerGroup => (
                        // Apply the header row props
                        <tr {...headerGroup.getHeaderGroupProps()}>
                        {// Loop over the headers in each row
                        headerGroup.headers.map(column => (
                            // Apply the header cell props
                            <th {...column.getHeaderProps((column as any).getSortByToggleProps())} className="p-1.5 whitespace-nowrap">
                            {// Render the header
                                column.render('Header')}
                                <div className='w-4 ml-1 inline-block'>
                                    { (column as any).isSorted ? ((column as any).isSortedDesc ? '▼' : '▲') : ''}
                                </div>
                            </th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    {/* Apply the table body props */}
                    <tbody {...getTableBodyProps()}>
                    {// Loop over the table rows
                    page.map(row => {
                        // Prepare the row for display
                        prepareRow(row)
                        return (
                        // Apply the row props
                        <tr {...row.getRowProps()} className="even:bg-slate-700 odd:bg-slate-600">
                            {// Loop over the rows cells
                            row.cells.map(cell => {
                            // Apply the cell props
                            return (
                                <td {...cell.getCellProps()} className="p-1.5 whitespace-nowrap">
                                {// Render the cell contents
                                cell.render('Cell')}
                                </td>
                            )
                            })}
                        </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            <div className='w-full mt-2 mb-4'>
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className={buttonClass}>
                {'<<'}
                </button>{' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage} className={buttonClass}>
                {'<'}
                </button>{' '}
                <button onClick={() => nextPage()} disabled={!canNextPage} className={buttonClass}>
                {'>'}
                </button>{' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className={buttonClass}>
                {'>>'}
                </button>
                <span className='ml-2'>
                    Page: {pageIndex + 1} / {pageCount}
                </span>
            </div>
        </div>
    )
   
}

export default FuturesTable