import moment from "moment";
import { Link } from "react-router-dom";
import Advert from "../components/Advert";
import { BasisMiniChart } from "../components/BasisMiniChart";
import { BasisTypeIcon } from "../components/BasisTypeIcon";
import { CryptoIcon } from "../components/CryptoIcon";
import { ExchangeIcon } from "../components/ExchangeIcon";
import { ExpandableDescription } from "../components/ExpandableDescription";
import { FundingMiniChart } from "../components/FundingMiniChart";
import { ImpactPercentage } from "../components/ImpactPercentage";
import ShareButtons from "../components/ShareButtons";
import { SideIcon } from "../components/SideIcon";
import { getReferralBaseLink } from "../referral";

interface VariableAndFixedBasisTradeProps {
  id: string;
  trade: any;
}

function VariableAndFixedBasisTrade(props: VariableAndFixedBasisTradeProps) {
  const { trade } = props;

  const isVariable = trade.type === "VariableBasis";

  const variableSpiel =
    "A variable basis trade is going long spot, and short a perpetual future. The return is estimated from the average 7 day funding rate. The trade is assumed to be held for 7 days. Due to the variability of the funding rate, returns for this trade can be highly variable.";
  const fixedSpiel =
    "A fixed basis trade is going long spot, and short a future. The return is estimated by using the current basis. The trade is assumed to be held until the future expires.";

  const columns = [
    "Type",
    "Notional",
    "Currency",
    "Duration",
    "% Return",
    "% Ann. Return",
    "Last Updated",
    "Share",
  ];
  const values = [
    <BasisTypeIcon type={trade.type} />,
    trade.notional.toLocaleString(),
    trade.quoteCurrency,
    `${trade.payload.holdTimeDays.toFixed(1)} days`,
    <span
      className={trade.payload.return > 0 ? "text-green-500" : "text-red-500"}
    >{`~${(100 * trade.payload.return).toFixed(2)}%`}</span>,
    <span
      className={`text-2xl ${
        trade.payload.annualizedReturn > 0 ? "text-green-500" : "text-red-500"
      }`}
    >{`~${(100 * trade.payload.annualizedReturn).toFixed(2)}%`}</span>,
    moment(trade.timestamp).fromNow(),
    <ShareButtons />,
  ];
  const summary = [];
  for (let i = 0; i < columns.length; i++) {
    summary.push(
      <div key={i} className="flex lg:flex-row flex-row text-lg">
        <div className="text-center font-bold">{columns[i]}:</div>
        <div className="text-center ml-1">{values[i]}</div>
      </div>
    );
  }

  // TODO: Get this from API?
  const underlying = trade.payload.underlying;
  const spotLeg = [];
  spotLeg.push([
    "Symbol",
    <span>
      <CryptoIcon
        className="w-4 h-4 inline-block mr-1 mb-1"
        name={underlying}
      />
      <a href={trade.payload.spotLeg.tradeLink}>
        {trade.payload.spotLeg.symbol}
      </a>
    </span>,
  ]);
  spotLeg.push([
    "Exchange",
    <span>
      <ExchangeIcon
        className="w-4 inline-block mb-1 mr-1"
        name={trade.payload.spotLeg.exchange}
      />
      <a href={getReferralBaseLink(trade.payload.spotLeg.exchange)}>
        {trade.payload.spotLeg.exchange}
      </a>
    </span>,
  ]);
  spotLeg.push(["Side", <SideIcon side={trade.payload.spotLeg.side} />]);
  spotLeg.push(["Quantity", trade.payload.spotLeg.quantity.toLocaleString()]);
  spotLeg.push([
    "Fee",
    <span>{`${(100 * trade.payload.spotLeg.fees).toFixed(3)}%`}</span>,
  ]);
  spotLeg.push([
    "Spread",
    <ImpactPercentage pct={100 * trade.payload.spotLeg.spread} />,
  ]);
  spotLeg.push([
    "Slippage",
    <ImpactPercentage pct={100 * trade.payload.spotLeg.slippage} />,
  ]);
  spotLeg.push([
    "Price Impact",
    <ImpactPercentage pct={100 * trade.payload.spotLeg.impact} />,
  ]);

  const spotLegElements = [];
  for (const x of spotLeg) {
    spotLegElements.push(
      <div key={x[0]} className="flex lg:flex-row flex-row text-lg">
        <div className="text-center font-bold">{x[0]}:</div>
        <div className="text-center ml-1">{x[1]}</div>
      </div>
    );
  }

  const derivPayload = isVariable
    ? trade.payload.perpetualLeg
    : trade.payload.futureLeg;

  const derivLeg = [];
  derivLeg.push([
    "Symbol",
    <span>
      <CryptoIcon
        className="w-4 h-4 inline-block mr-1 mb-1"
        name={underlying}
      />
      <a href={derivPayload.tradeLink}>{derivPayload.symbol}</a>
      <Link
        className="no-underline"
        to={`/${isVariable ? "funding" : "basis"}?exchange=${
          derivPayload.exchange
        }&symbol=${derivPayload.symbol}`}
      >
        {" "}
        <img
          src="/img/icons/chart-area-solid.svg"
          className="w-5 inline-block ml-1 mb-1"
          alt={`${isVariable ? "Funding" : "Basis"} History`}
          title={`${isVariable ? "Funding" : "Basis"} History`}
        />
      </Link>
    </span>,
  ]);
  derivLeg.push([
    "Exchange",
    <span>
      <ExchangeIcon
        className="w-4 inline-block mb-1 mr-1"
        name={derivPayload.exchange}
      />
      <a href={getReferralBaseLink(derivPayload.exchange)}>
        {derivPayload.exchange}
      </a>
    </span>,
  ]);
  derivLeg.push(["Side", <SideIcon side={derivPayload.side} />]);
  derivLeg.push(["Quantity", derivPayload.quantity.toLocaleString()]);
  derivLeg.push([
    "Fee",
    <span>{`${(100 * derivPayload.fees).toFixed(3)}%`}</span>,
  ]);
  derivLeg.push([
    "Spread",
    <ImpactPercentage pct={100 * derivPayload.spread} />,
  ]);
  derivLeg.push([
    "Slippage",
    <ImpactPercentage pct={100 * derivPayload.slippage} />,
  ]);
  derivLeg.push([
    "Price Impact",
    <ImpactPercentage pct={100 * derivPayload.impact} />,
  ]);

  const derivLegElements = [];
  for (const x of derivLeg) {
    derivLegElements.push(
      <div key={x[0]} className="flex lg:flex-row flex-row text-lg">
        <div className="text-center font-bold">{x[0]}:</div>
        <div className="text-center ml-1">{x[1]}</div>
      </div>
    );
  }

  const sameExchange = trade.payload.spotLeg.exchange === derivPayload.exchange;

  const holdTimeText = isVariable
    ? `Variable rate trades are assumed to be held for ${derivPayload.holdTimeDays} days, using the ${derivPayload.holdTimeDays} day average funding rate.`
    : "Fixed rate trades are assumed to be held to expiry.";

  const collateralSentence = `The ${underlying} is worth ${(
    100 * derivPayload.collateralWeight
  ).toFixed(1)}% of it's value on ${
    derivPayload.exchange
  }. You may need to add additional ${
    trade.quoteCurrency
  } or use leverage to fully execute the trade.`;

  return (
    <div className="w-full mx-auto">
      <Advert />
      <div className="flex flex-col lg:flex-row">
        <div className="bg-slate-600 rounded-md p-2 flex-grow lg:mr-2 basis-0">
          <h1 className="mb-4">Trade Summary</h1>
          {summary}
          <strong>Description: </strong>
          <ExpandableDescription
            text={isVariable ? variableSpiel : fixedSpiel}
          />
          <small className="text-neutral-300">
            *All returns are approximate.
          </small>
          <br />
          <small className="text-neutral-300">{`*${holdTimeText}`}</small>
        </div>
        <div className="bg-slate-600 rounded-md p-2 flex-grow lg:ml-2 mt-4 lg:mt-0 basis-0">
          <h1 className="mb-4">{`${
            isVariable ? "Funding" : "Basis"
          } History`}</h1>
          {isVariable ? (
            <FundingMiniChart
              series={[
                {
                  exchange: trade.payload.perpetualLeg.exchange,
                  symbol: trade.payload.perpetualLeg.symbol,
                },
              ]}
            />
          ) : (
            <BasisMiniChart
              exchange={trade.payload.futureLeg.exchange}
              symbol={trade.payload.futureLeg.symbol}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="bg-slate-600 rounded-md p-2 lg:mr-2 flex-grow mt-4 basis-0">
          <h1 className="mb-4">Spot Leg</h1>
          {spotLegElements}
        </div>
        <div className="bg-slate-600 rounded-md p-2 lg:ml-2 flex-grow mt-4 basis-0">
          <h1 className="mb-4">{`${
            trade.type === "VariableBasis" ? "Perpetual" : "Future"
          } Leg`}</h1>
          {derivLegElements}
        </div>
      </div>
      <div className="bg-slate-600 rounded-md p-2 mt-4">
        <h1 className="mb-2">Instructions</h1>
        <div className="text-sm mb-4">
          <span className="text-red-500 font-bold">RISK DISCLAIMER:</span>{" "}
          Trading cryptocurrencies carries a high level of risk, and may not be
          suitable for all investors. This website and its contents are for
          informational purposes only, and none of it is intended to constitute,
          or constitutes, financial advice or a recommendation or inducement to
          enter into any transaction. The data displayed on this website
          includes data taken from external sources and no guarantee or warranty
          is provided as to its accuracy, reliability, availability or safety.
          If you choose to make use of any of the information displayed on this
          website, you do so entirely at your own risk - cryptoncarry.com is not
          responsible for any losses suffered by any party in connection with
          that use, including trading losses.
        </div>
        <div className="pl-6">
          <ol className="text-lg list-decimal space-y-1">
            <li>
              Go to{" "}
              <a
                href={getReferralBaseLink(trade.payload.spotLeg.exchange)}
              >{`${trade.payload.spotLeg.exchange}`}</a>{" "}
              and sign up for an account.
            </li>

            <li>
              <span className="font-bold text-red-500">IMPORTANT: </span>Verify
              that the current market conditions for this trade are still
              viable.
            </li>

            <li>
              {trade.payload.spotLeg.side === "buy" ? "Buy" : "Sell"}{" "}
              {trade.payload.spotLeg.quantity.toLocaleString()} {underlying}{" "}
              using{" "}
              <a href={trade.payload.spotLeg.tradeLink}>
                {trade.payload.spotLeg.symbol}
              </a>
              .
            </li>

            {!sameExchange && (
              <li>
                Sign up to{" "}
                <a href={getReferralBaseLink(derivPayload.exchange)}>
                  {derivPayload.exchange}
                </a>
                .
              </li>
            )}

            {!sameExchange && derivPayload.collateralWeight > 0 && (
              <li>
                Transfer the {underlying} to {derivPayload.exchange} for use as
                collateral.
              </li>
            )}

            {derivPayload.collateralWeight < 1 && <li>{collateralSentence}</li>}

            <li>
              {derivPayload.side === "buy" ? "Buy" : "Sell"}{" "}
              {trade.payload.spotLeg.quantity.toLocaleString()} {underlying} of{" "}
              <a href={derivPayload.tradeLink}>{derivPayload.symbol}</a>{" "}
              {derivPayload.isInverse &&
                `(~${derivPayload.quantity.toLocaleString()} contracts)`}
              .
            </li>

            {isVariable && (
              <li>
                Keep track of the funding rate to make sure that the trade does
                not start to become unprofitable. It is possible to keep the
                trade going indefinitely while funding is favourable.
              </li>
            )}

            {derivPayload.exchange === "FTX" && (
              <li>
                <span className="font-bold text-red-500">WARNING: </span>On FTX,
                under certain conditions (such as your negative USD balance
                being in excess of $30,000) your non-USD collateral{" "}
                <a href="https://help.ftx.com/hc/en-us/articles/360031149632-Non-USD-Collateral">
                  may be liquidated
                </a>
                .
              </li>
            )}

            {!isVariable && (
              <li>
                Hold the trade for {trade.payload.holdTimeDays.toFixed(1)} days
                until the future settles.
              </li>
            )}
          </ol>
        </div>
      </div>
      <div className="bg-slate-600 rounded-md p-2 mt-4">
        <h1 className="mb-4">Risks</h1>
        <ol className="text-lg list-item space-y-4">
          <li>
            <strong className="text-red-500">💦 Liquidation Risk</strong>
            <br /> When selling the {derivPayload.product}, if less collateral
            is used then there is risk of liquidation. Pay close attention to
            how your collateral is valued on the exchange.
          </li>
          {isVariable && (
            <li>
              <strong className="text-red-500">💰 Funding Risk</strong>
              <br /> With perpetual futures, the funding rate can become
              negative meaning that funding will be paid instead of received.
              Pay attention to the funding rate and consider closing the trade
              if the funding is consistently unfavourable.
            </li>
          )}
          {isVariable && (
            <li>
              <strong className="text-red-500">📉 Basis Risk</strong>
              <br /> With perpetual futures, the funding rate should keep the
              price of the perpetual tethered to the underlying. However, unlike
              with futures this is not guaranteed and the difference can
              increase or decrease leading to losses.
            </li>
          )}
          <li>
            <strong className="text-red-500">💣 Socialised Loss Risk</strong>
            <br /> Most exchanges will have some sort of{" "}
            <a href="https://www.machow.ski/posts/how-crypto-derivatives-exchanges-prevent-bankruptcy/">
              socialised loss mechanism
            </a>
            . During extreme market moves it is possible for your{" "}
            {derivPayload.product} to be closed early.
          </li>
          <li>
            <strong className="text-red-500">
              🏦 Exchange Counterparty Risk
            </strong>
            <br /> By putting funds in an exchange, you are putting your funds
            in their hands, and therefore at risk. Carrying out your own due
            dilligence is reccomended.
          </li>
        </ol>
      </div>
    </div>
  );
}

export default VariableAndFixedBasisTrade;
