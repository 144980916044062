import { Link } from 'react-router-dom'

function NavItem(props: any) {
    const text = props.text
    const to = props.to
    const onClick = props.onClick
    return (
        <li>
            <Link to={to} onClick={onClick} className="px-2 lg:px-6 font-bold leading-[22px] text-neutral-300 hover:text-neutral-100 cursor-pointer">
                { text }
            </Link>
        </li>
    )
  }
  
  export default NavItem;
  