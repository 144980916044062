import { useEffect, useState } from 'react'
import { fetchCollateral } from '../api'
import Advert from '../components/Advert'
import CollateralTable from '../components/CollateralTable'

function Collateral() {
    const [collateralData,setCollateralData] = useState([] as any[])
    useEffect(() => {
      const getData = async () => {
        const data = await fetchCollateral()
        setCollateralData(data)
      }
      getData()
    },[])
    return (
        <div className="w-full mx-auto">
            <Advert />
            <h1>Collateral Weights</h1>
            <p className="text-sm text-neutral-500">List of collateral weightings on exhchanges with multi asset margining systems.</p>
            <CollateralTable data={collateralData}/>
        </div>
    )
}

export default Collateral