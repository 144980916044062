import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import DarkUnica from 'highcharts/themes/dark-unica'
import { useEffect, useState } from 'react'
import { fetchBasis } from '../api'

export const SECONDS_IN_DAY = 24 * 60 * 60

const DEFAULT_CHART: any = {
    chart: {
        backgroundColor: '#334155',
        borderRadius: '0.5rem',
        height: '337rem'
    }
}

interface BasisMiniChartProps {
    exchange: string
    symbol: string
}

export function BasisMiniChart(props: BasisMiniChartProps) {
    const [options,setOptions] = useState(DEFAULT_CHART)

    DarkUnica(Highcharts)

    useEffect(() => {
        if(props.exchange === undefined || props.symbol === undefined) {
            return
        }
        const getData = async () => {
            const end = Math.ceil((new Date()).getTime() / 1000)
            const start = end - 30 * SECONDS_IN_DAY
            const data = await fetchBasis(props.exchange,props.symbol,start,end)
            const formatted = data.map((x: any) => [new Date(x.timestamp).getTime() ,100 * x.basisFrac])
            const yMin = Math.min(0,...formatted.map((x: any) => x[1]))
            const yMax = Math.max(0,...formatted.map((x: any) => x[1]))
            setOptions({
                ...DEFAULT_CHART,
                series: [
                    {
                        name: 'Basis (%)',
                        data: formatted,
                        color: '#fcd34d'
                    }
                ],
                yAxis: {
                    title: {
                        text: 'Basis (%)'
                    },
                    plotLines: [
                        {
                            value: 0,
                            width: 3,
                            color: '#0284c7'
                        }
                    ],
                    max: yMax,
                    min: yMin
                },
                xAxis: {
                    type: "datetime",
                    ordinal: false
                },
                navigator: {
                    enabled: false
                }
            })
          }
          getData()
    },[props.exchange,props.symbol])

    return (
        <div className='mt-4'>
            <HighchartsReact 
                highcharts={Highcharts} 
                constructorType={'stockChart'}
                options={options}
            />
        </div>
    )
}